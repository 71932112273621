import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios'
import React,{useState} from 'react'
import Button, { OutlineButton } from '../../components/button/Button'
import './Seasons.scss'
import Adcomp from '../../ads/Adcomp';
const Seasons = (props) => {
  const [dloading,setdloading]=useState(false);
  const history = useHistory();
  
  const playHandler=(value,valueid)=>
  {
    try {
     var temp = value.split("/");
     var idval=temp[4].split(".");
     var playid=idval[0];
     playid='nothing'
     if(props.hditems!=undefined && Object.keys(props.hditems).length!=0)
    { 
    var temp2 = props.hditems[props.item][valueid].split("/");
    var idval2=temp2[4].split(".");
    var playid2=idval2[0];
  playid2='nothing'}
    else
    {
      playid2='nothing'
    }
    if(props.streamwishitems!=undefined && Object.keys(props.streamwishitems).length!=0 )
    { 
      var temp3 = props.streamwishitems[props.item][valueid].split("/");
      var idval3=temp3[4].split(".");
      var playid3=idval3[0];}
    else
      {
        playid3='nothing'
      }
      if(props.streamrubyitems!=undefined && Object.keys(props.streamrubyitems).length!=0 )
    { 
      var temp5 = props.streamrubyitems[props.item][valueid].split("/");
      var idval5=temp5[4].split(".");
      var playid5=idval5[0];}
    else
      {
        playid5='nothing'
      }
      if(props.filelionsitems!=undefined && Object.keys(props.filelionsitems).length!=0 )
      { 
        var temp4 = props.filelionsitems[props.item][valueid].split("/");
        var idval4=temp4[4].split(".");
        var playid4=idval4[0];}
      else
        {
          playid4='nothing'
        }
     history.push(`/play/${playid}-${playid2}-${playid3}-${playid4}-${playid5}`);
    
  } catch (error){
      var errorMessage = error;
      console.log(error);
  }
  };
  const setModalActive =  (episode,id) => {
    var url=episode;
    url=url.replace('play','d');
    // if(id%3===0 || id===1)
    // {
    //   // const apicall = `https://shrinkme.io/api?api=5f859d1a8a03b012370cbe649bd1a6dfcac85fe6&url=${url}`
    //   // axios(apicall)
    //   //   .then((response) => {
    //   //     var shorturl = response.data.shortenedUrl;
    //   //     setdloading(false);
    //   //     window.open(shorturl||url, "_blank")?.focus();
    //   //   })
    //   //   .catch((error) => console.log(error))
    //   const link=`https://api.shareus.in/directLink?token=McizE165AGcFFwNuMeNGaMeouUl1&link=${url}`
    //   props.setCountDp(true);
    //   props.setUrlCount(link||url)
    //   //window.open(link, "_blank")?.focus();
    // }
    // else {
      props.setCountDp(true);
      props.setUrlCount(null);
      if(props.streamwishitems!=undefined && Object.keys(props.streamwishitems).length!=0 )
    { 
      var temp4 = props.streamwishitems[props.item][id].split("/");
      var idval4=temp4[4].split(".");
      var playid4=idval4[0];
      if(playid4==='nothing')
      {
        props.setstreamwishurl(null);
      }
      else{
      props.setstreamwishurl(playid4);}
    }
    if(props.streamrubyitems!=undefined && Object.keys(props.streamrubyitems).length!=0 )
    { 
      var temp5 = props.streamrubyitems[props.item][id].split("/");
      var idval5=temp5[4].split(".");
      var playid5=idval5[0];
      if(playid5==='nothing')
      {
        props.setstreamrubyurl(null);
      }
      else{
      props.setstreamrubyurl(playid5);}
    }
    if(props.filelionsitems!=undefined && Object.keys(props.filelionsitems).length!=0 )
    { 
      var temp3 = props.filelionsitems[props.item][id].split("/");
      var idval3=temp3[4].split(".");
      var playid3=idval3[0];
      if(playid3==='nothing')
      {
        props.setfilelionsurl(null);
      }
      else{
      props.setfilelionsurl(playid3);}
    }
     // window.open(url, "_blank")?.focus();
    // }
    }
  return (
    <div className='seasoncard'>
     
        <h1 style={{color:'#ff8000',padding:'15px',fontWeight:'bolder'}}>{props.item}</h1>
        <h3 style={{color:'black',padding:'15px',textAlign:'center'}}>Play Online<PlayCircleIcon style={{fontSize:'medium',marginLeft:'2px',marginTop:'2px'}}></PlayCircleIcon></h3>
       <div key={`p${props.id}`}> {props.items[props.item].map((episode,id)=>( <span key={`d${id}`}>
               {episode!==null&&<span key={`p${id}`}><Button onClick={()=>playHandler(episode,id)} className="butn">{`Episode ${id}`} <PlayCircleIcon style={{fontSize:'medium',marginLeft:'2px',marginTop:'2px'}}></PlayCircleIcon></Button></span>}
               </span>))

        }   </div>
        {/* <Adcomp id='343745'></Adcomp>mobile */}
        <h3 style={{color:'black',padding:'15px',textAlign:'center'}}>Download<DownloadIcon style={{fontSize:'medium',marginLeft:'2px',marginTop:'2px'}}></DownloadIcon></h3>
        {/* <span style={{textAlign:'center'}}><a href="https://vimeo.com/728337802" style={{fontSize:'14px',color:'#b8b45a'}}> (👉 How to Download?)</a></span> */}
      <div  key={`d${props.id}`} style={{paddingTop:'20px'}}>  {props.items[props.item].map((episode,id)=>( <span key={`d${id}`}>
               {episode!==null&&<span><OutlineButton onClick={()=>setModalActive(episode,id)} className="botn">{dloading? <span>Getting Link...</span>:<>{`Episode ${id}`}<DownloadIcon style={{fontSize:'medium',marginLeft:'2px',marginTop:'2px'}}></DownloadIcon></>}</OutlineButton></span>}
               </span>))

        }  </div>
        
       {/* <Adcomp id='343745'></Adcomp>mobileh */}
    </div>
  )
}

export default Seasons