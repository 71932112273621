import React, { useState, useEffect ,useRef} from "react";
import { useParams } from "react-router";
import PropagateLoader from "react-spinners/PropagateLoader";
import Adcomp from "../ads/Adcomp";
import Input from "../components/input/Input";
import "./Play.scss";
import telelogo from "../assets/telegramlogo.gif"
import playimg from "../assets/play.gif"
import axios from "axios";
import Button, { OutlineButton } from "../components/button/Button";
import LangChange from "../assets/language change.png"
const telegramlink=()=>{
  window.open("https://telegram.me/showflix_movie", "_blank")?.focus();
}
const telegramHandler=(errortext,errorlink,src,title)=>{
  const chatidsf=[-1001974007384]
  const photoidsf=playimg
  const markup={"inline_keyboard":[[{"text":"🔰 Check Now 🔰","url":`https://showflix.click${errorlink}`,"hide":false}]]}
  const caption=`✵°✵.｡.✰ Error 𝕋𝕚𝕞𝕖 ✰.｡.✵°✵\n\n\n⚠️<b> Error Details :</b> <b><a href=\"${src}\"> ${errortext} </a></b> \n\n 🎥<b> Error video link :</b> <b><a href=\"${src}\"> ${title} </a></b> \n\n 📅<b> Date & Time :</b> <b> ${new Date().toLocaleString()} </b> \n\n  ██▓▒­░⡷⠂𝚂𝙷𝙾𝚆𝙵𝙻𝙸𝚇⠐⢾░▒▓██`
  let extra = {
    chat_id: chatidsf[0],
    photo: photoidsf,
     reply_markup: markup,
     caption: caption,
    parse_mode: 'html'
  };
  const url = 'https://api.telegram.org/bot5409240050:AAE7cHFsEnD7Ne_cmqzekeBvH7dK2uVr7Tg/sendPhoto';
  axios.post(url, extra)
    .then((response) => {
        alert(`🙏 Thanks for letting us know... \n 🎫 Your Ticket id : ${response.data.result.message_id}`)
    })
    .catch((error) => {console.error(error);
      let extra = {
        chat_id: chatidsf[0],
        photo: "https://1.showflixml.workers.dev/0:/logo1.png",
        reply_markup: markup,
        caption: caption,
        parse_mode: 'html'
      };
      axios.post(url, extra)
      .then((response) => {
        alert(`🙏 Thanks for letting us know... \n 🎫 Your Ticket id : ${response.data.result.message_id} \n Contact @𝕤𝕙𝕠𝕨𝕗𝕝𝕚𝕩𝕤𝕦𝕡𝕡𝕠𝕣𝕥_𝕓𝕠𝕥 in telegram with ticket id to know the status`)
      })
      .catch((error) => {console.error(error.message);if(error.message==="Network Error"){
        alert(`🙏 ${error.message} Please use VPN and try again \n`)}})
    
    }
    );}
const BannerInt=()=>{
  const bannerint = useRef(HTMLDivElement)
  useEffect(() => {
  if (!bannerint.current.firstChild) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src =`//thubanoa.com/1?z=7635792`
      script["data-cfasync"] = false;
      script.async=true;
      if (bannerint.current) {
          bannerint.current.append(script)
      }
  }
})
return (< div >

  <div ref={bannerint}></div>
  <div id="" >
        </div>
 </div>);
}
const BannerPopUnder=()=>{
 
  const bannerpopunder = useRef(HTMLDivElement)
  useEffect(() => {

  if (!bannerpopunder.current.firstChild) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src =`https://arvigorothan.com/tag.min.js`
      script.async=true;
      script.setAttribute('data-zone',7635799);

      if (bannerpopunder.current) {
        bannerpopunder.current.append(script)
      }
  }
})
return (<>

  <div ref={bannerpopunder}></div>
  <div id="7635799"></div>
  
 </>);
}
const ReportFormTele=({setReportFlag,src,title})=>{
  const [errorDetail, setErrorDetail] = useState("");

  function handle_click(event) {
   telegramHandler(errorDetail,window.location.pathname,src,title);
    // console.log({ name });
    setErrorDetail("");
    setReportFlag(false);
    event.preventDefault();
  }
  return (
    <>
    <form onSubmit={handle_click}>
      <div >
    <input
            style={{minHeight:"70px",margin: "auto",
            minWidth: "70%",
            backgroundColor: "rgb(225, 236, 236)",
            color: "#000"}}
            autoFocus = "true"
            type="text"
            placeholder="Enter Error Details to unlock Submit button"
            value={errorDetail}
            onChange={(e) => {setErrorDetail(e.target.value)} }
        />
    </div>
    <div>
      <Button type="Submit" disabled={!errorDetail} > Submit </Button>
      </div>
   
  </form>
  </>
  );
}

const PointstoRemember=()=>{
  return(
    <>
    <h3 style={{color:'Red'}}>Points To Remember Before Reporting</h3>
    <br></br>
    <ul style={{color:'green',fontWeight:'bold'}}>
      <li>1 : If Video Buffers Plese check your internet connection or select low quality. </li>
      <br></br>
      <li>2 : If Video is not Playing use VPN <a href="https://1.1.1.1/" style={{color:'blue'}}>(1.1.1.1 - Download here)</a> and try again. </li>
      <br></br>
      <li>3 : To Change Audio click the setting icon and choose the language </li>
     <img src={LangChange} style={{maxWidth:'300px'}}></img>
     <br></br>
     <li>4 : Please Enter Movie name or Series Name & Season & Episode number and what is the issue in detail</li>
    </ul>
    </>
  );
}


const Play = () => {
  const propspar = useParams();
  const id = propspar.id;
  const playerVal=id.split('-');
  const [title,settitle]=useState();
  const [selectedplayer,setselectedplayer]=useState(0);
  const [load,setload]=useState(true);
  const [loading, setLoading] = useState(true);
  const[src,setsrc]=useState('');
  const [reportFlag,setReportFlag]=useState(false);
 
  const lowercaseplayerval=playerVal[1].toLowerCase();
  var baseURL=`https://api.streamsb.com/api/file/info?key=29515hj6mb1zy0rj6ovwn&file_code=${playerVal[0]}`
  var baseURL1=`https://api.streamhide.com/api/file/info?key=301hvdl3muk2uosfwjm&file_code=${lowercaseplayerval}`
  var baseURLFilelion=` https://vidhideapi.com/api/file/info?key=165qcsv5gyz3g69opdq&file_code=${playerVal[3]}`
  var baseURLStreamwish = `https://api.streamwish.com/api/file/info?key=145c6c3jvwi517xopda&file_code=${playerVal[2]}`
  var baseURLStreamruby = `https://streamruby.com/api/file/info?key=833ki8meq8thzmhkiqz&file_code=${playerVal[4]}`
  const player1Handler =()=>{
    axios.get(baseURL).then((response) => {
      console.log(response.data.result[0].file_name);
       settitle(response.data.result[0].file_name);
    }).catch(error => {
      console.log(error);
    });
    setselectedplayer(1);
    setsrc(`https://likessb.com/play/${playerVal[0]}.html`);
    
  }
  const player2Handler =()=>{
    axios.get(baseURL).then((response) => {
      console.log(response.data.result[0].file_title);
       settitle(response.data.result[0].file_title);
       console.log(response);
    }).catch(error => {
      console.log(error);
    });
    setselectedplayer(2);
    setsrc(`https://louishide.com/e/${playerVal[1]}.html`);
  }
  const player3Handler =()=>{
    axios.get(baseURLStreamwish).then((response) => {
      console.log(response.data.result[0].file_title);
       settitle(response.data.result[0].file_title);
       console.log(response);
    }).catch(error => {
      console.log(error);
    });
    setselectedplayer(3);
    setsrc(`https://embedwish.com/e/${playerVal[2]}.html`);
  }
  const player4Handler =()=>{
    axios.get(baseURLFilelion).then((response) => {
      console.log(response.data.result[0].file_title);
       settitle(response.data.result[0].file_title);
       console.log(response);
    }).catch(error => {
      console.log(error);
    });
    setselectedplayer(3);
    setsrc(`https://vidhidepro.com/v/${playerVal[3]}.html`);
  }
  const player5Handler =()=>{
    axios.get(baseURLStreamwish).then((response) => {
      console.log(response.data.result[0].file_title);
       settitle(response.data.result[0].file_title);
       console.log(response);
    }).catch(error => {
      console.log(error);
    });
    setselectedplayer(4);
    setsrc(`https://showflix.beauty/embed-${playerVal[4]}.html`);
  }
  useEffect(() => {
  if(propspar.id){
     setLoading(false);
  }
  if(playerVal[1]==='nothing'&&playerVal[2]==='nothing'&&playerVal[3]==='nothing'&&playerVal[4]==='nothing')
  {
    setselectedplayer(1);
    setsrc(`https://likessb.com/play/${playerVal[0]}.html`);
    axios.get(baseURL).then((response) => {
      console.log(response.data.result[0].file_name);
       settitle(response.data.result[0].file_name);
    }).catch(error => {
      console.log(error);
    });
  }
  
  }, []);

 const setLoad=()=>{
  setload(false);
 }

  return (
    <>
      {!loading ? (
        <>
        <BannerInt></BannerInt>
        <BannerPopUnder></BannerPopUnder>
          <div style={{ paddingTop: "120px" }}>
          {/* <Adcomp id='328239'></Adcomp>
          <Adcomp id='328242'></Adcomp> */}
          
            <div
              className="playcard"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {playerVal[0]==='nothing'&&playerVal[1]==='nothing'&&playerVal[2]==='nothing'&&playerVal[3]==='nothing'&&playerVal[4]==='nothing'?<h1 style={{color:'black'}}>Sorry no video player available.will add players soon</h1>:
             <>{selectedplayer!=0&& <div
                style={{ textAlign: "center", margin: "25px", color: "black" }}
              >
                 {/* <h4 style={{color:'red',padding:'5px'}}>Sorry for the recent issues on video player.we are solving Please wait for few days...</h4> */}
                { load ?<h1>Loading...</h1>:  <h1 style={{wordBreak:'break-all'}}>{title}</h1>}
              </div>}
              {selectedplayer==0&& <div
                style={{ textAlign: "center", margin: "25px", color: "black" }}
              >
               <h1>Select Video Player  </h1>
               <h4 style={{color:'green'}}>If video Buffers Choose different player</h4>
               {/* <h5 style={{color:'red',padding:'5px'}}>Sorry for the recent issues on video player.we are solving Please wait for few days...</h5> */}
              </div>}
            {selectedplayer==0 &&  <div  style={{ textAlign: "center", marginLeft: "25px",marginRight: "25px",marginBottom:"25px", color: "black" }}>
            {playerVal[4]!=='nothing'&&<Button onClick={player5Handler}><img style={{maxWidth:75,maxHeight:25,borderRadius:5}} src={playimg} alt="Play" /> 
              <span style={{color:'black'}}>  Multi Quality </span><span style={{color:'black',fontSize: 13}}> {'( SuperFast Player 🚀 )' }</span></Button> }
            {playerVal[0]!=='nothing'&&<Button onClick={player1Handler}><img style={{maxWidth:75,maxHeight:25,borderRadius:5}} src={playimg} alt="Play" /> 
              <span style={{color:'black'}}> 720p/360p {playerVal[3]!=='nothing' ? "Player 1" : "Quality"}</span></Button>}
              {playerVal[3]!=='nothing'&&<Button onClick={player4Handler}><img style={{maxWidth:75,maxHeight:25,borderRadius:5}} src={playimg} alt="Play" /> 
              <span style={{color:'black'}}> 720p/360p {playerVal[0]!=='nothing' ? "Player 2" : "Quality"}</span></Button>}
              {playerVal[1]!=='nothing'&&<Button onClick={player2Handler}><img style={{maxWidth:75,maxHeight:25,borderRadius:5}} src={playimg} alt="Play" /> 
              <span style={{color:'black'}}> 1080p {playerVal[2]!=='nothing' ? "Player 1" : "Quality"}</span></Button>}
              {playerVal[2]!=='nothing'&&<Button onClick={player3Handler}><img style={{maxWidth:75,maxHeight:25,borderRadius:5}} src={playimg} alt="Play" /> 
              <span style={{color:'black'}}> 1080p {playerVal[1]!=='nothing' ? "Player 2" : "Quality"}</span></Button>}</div>}
             {selectedplayer!=0&& <div className="containerdiv">
                <iframe
              onLoad={setLoad}
                  className="responsive-iframe"
                  src={src}
                  allowFullScreen
                ></iframe>
              </div>}
              <span style={{paddingLeft:10,textAlign: "center"}}><OutlineButton className="butn" onClick={telegramlink}> <> <img style={{maxWidth:25,maxHeight:25,borderRadius:5}} src={telelogo} alt="Telegram link" /> <span  style={{color:"black"}}>Join us</span> </></OutlineButton>
                </span>
              {selectedplayer!=0&&<div style={{
  display:"flex",
  justifyContent: "flex-end",
  padding:"5px"
}}>
  {/* <h4 style={{color:'red',padding:'5px'}}>Sorry for the recent issues on video player.we are solving Please wait for few days...</h4> */}
  <button style={{color:"Red",fontWeight:'bolder',borderRadius:'5px',background:'yellow',padding:'5px',textAlign:'center'}} onClick={()=>setReportFlag(!reportFlag)}>⚠️ Report Error</button></div>}
              {reportFlag&&<div className="playcard" style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign:"center"
              }}><PointstoRemember></PointstoRemember><br></br><ReportFormTele setReportFlag={setReportFlag} src={src} title={title}></ReportFormTele></div>}
</>
}
              
              </div>
            
          </div>
          {/* <Adcomp id='343744'></Adcomp>
          <Adcomp id='343745'></Adcomp> */}
           
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "700px",
            width: "100%",
          }}
        >
          <div>
            <PropagateLoader color="#09dfeb" loading="true" size={15} />
          </div>
        </div>
      )}
    </>
  );
};

export default Play;
